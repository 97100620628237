import "core-js/modules/es.array.push.js";
import router from '@/router/index';
import { findById } from '@/api/questionnaire';
import { selectAll } from '@/api/questionnaireitem';
import { insertAnswerAndItem } from '@/api/answer';
import { getWxJsConfig, getAddress } from "@/api/user";
import { uploadFile } from "@/api/file";
import wx from 'weixin-js-sdk';
export default {
  components: {},
  data() {
    return {
      localId: '',
      serverId: '',
      questionnaireData: {
        id: '',
        title: '',
        summary: '',
        publish: false
      },
      questionnaireItemList: []
    };
  },
  created: async function () {
    //获取id
    let id = this.$route.query.id;
    if (!id) {
      router.replace({
        path: "/wechatuser/home"
      });
      return 0;
    }
    const ajaxData = {};
    ajaxData.url = window.location.href.split('#')[0];
    const resultData = await getWxJsConfig(ajaxData);
    if (resultData.code == 200) {
      wx.config({
        debug: false,
        // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: resultData.data.appId,
        // 必填，公众号的唯一标识
        timestamp: resultData.data.timestamp,
        // 必填，公众号的唯一标识
        nonceStr: resultData.data.nonceStr,
        // 必填，生成签名的随机串
        signature: resultData.data.signature,
        // 必填，签名
        jsApiList: ["getLocation", "startRecord", "stopRecord", "onVoiceRecordEnd", "playVoice", "stopVoice", "onVoicePlayEnd", "uploadVoice"] // 必填，需要使用的JS接口列表
      });
    }

    this.findQuestionnaire(id);
  },
  beforeMount: function () {},
  mounted: function () {},
  methods: {
    async findQuestionnaire(id) {
      const ajaxData = {};
      ajaxData.id = id;
      const resultData = await findById(ajaxData);
      if (resultData.code === 200) {
        this.questionnaireData = resultData.data;
        this.selectAllQuestionnaireItem();
      } else {
        router.replace({
          path: "/wechatuser/home"
        });
        return 0;
      }
    },
    async selectAllQuestionnaireItem() {
      const ajaxData = {};
      ajaxData.questionnaireId = this.questionnaireData.id;
      const resultData = await selectAll(ajaxData);
      if (resultData.code === 200) {
        this.questionnaireItemList = resultData.data;
        for (const i in this.questionnaireItemList) {
          if (this.questionnaireItemList[i].type === 1) {
            this.questionnaireItemList[i].content = '';
          } else if (this.questionnaireItemList[i].type === 2) {
            this.questionnaireItemList[i].contentList = this.questionnaireItemList[i].content.split(';');
            this.questionnaireItemList[i].content = '';
          } else if (this.questionnaireItemList[i].type === 3) {
            this.questionnaireItemList[i].contentList = this.questionnaireItemList[i].content.split(';');
            this.questionnaireItemList[i].content = [];
          } else if (this.questionnaireItemList[i].type === 4) {
            this.questionnaireItemList[i].content = '';
          } else {
            this.questionnaireItemList[i].content = [];
            this.questionnaireItemList[i].fileList = [];
          }
        }
      }
    },
    async save() {
      const ajaxData = {};
      ajaxData.questionnaireId = this.questionnaireData.id;
      ajaxData.soundRecordingId = this.serverId;
      ajaxData.answerItemInsertReqVoList = [];
      for (const i in this.questionnaireItemList) {
        const data = {};
        data.questionnaireItemId = this.questionnaireItemList[i].id;
        if (this.questionnaireItemList[i].notEmpty == true && this.questionnaireItemList[i].content.length == 0) {
          this.$dialog.alert({
            title: '提示',
            message: '第' + (parseInt(i) + 1) + '个问题为必填项',
            confirmButtonText: '确认'
          }).then(() => {});
          return;
        }
        data.content = this.questionnaireItemList[i].content;
        //区分所属乡镇
        if (this.questionnaireItemList[i].type == 2 && this.questionnaireItemList[i].notEmpty == true && this.questionnaireItemList[i].title.substring(0, 5) == '您所在乡镇') {
          ajaxData.township = this.questionnaireItemList[i].contentList[this.questionnaireItemList[i].content];
        }
        //处理下多选
        if (this.questionnaireItemList[i].type == 3) {
          data.content = data.content.join(",");
        }
        if (this.questionnaireItemList[i].type == 5) {
          data.content = this.questionnaireItemList[i].fileList.join(",");
        }
        ajaxData.answerItemInsertReqVoList.push(data);
      }
      this.$toast.loading({
        message: '加载中...',
        //加载的时候显示的文字
        forbidClick: true //是否禁止背景点击
      });

      const resultData = await insertAnswerAndItem(ajaxData);
      this.$toast.clear();
      if (resultData.code == 200) {
        this.$toast.success("保存成功");
        router.replace({
          path: '/wechatuser/home'
        });
      } else {
        this.$toast.fail("保存失败");
      }
    },
    onOversize(file) {
      console.log(file);
      this.$notify({
        type: 'danger',
        message: '图片大小不能超过10M'
      });
    },
    async uploadFile(file, index) {
      console.log(file);
      console.log(index);
      const formData = new FormData();
      formData.append('file', file.file);
      const resultData = await uploadFile(formData);
      if (resultData.code == 200) {
        this.questionnaireItemList[index].fileList.push(resultData.data);
      }
    },
    startRecording() {
      console.log("长按开始");
      const that = this;
      wx.startRecord();
      wx.onVoiceRecordEnd({
        // 录音时间超过一分钟没有停止的时候会执行 complete 回调
        complete: function (res) {
          that.$notify({
            type: 'danger',
            message: '录音时间超时了'
          });
          that.localId = res.localId;
        }
      });
    },
    endRecording() {
      console.log("长按结束");
      const that = this;
      wx.stopRecord({
        success: function (res) {
          that.localId = res.localId;
        }
      });
    },
    playRecording() {
      const that = this;
      wx.playVoice({
        localId: that.localId // 需要播放的音频的本地ID，由stopRecord接口获得
      });
    },

    removeRecording() {
      const that = this;
      wx.stopVoice({
        localId: that.localId // 需要停止的音频的本地ID，由stopRecord接口获得
      });

      that.localId = '';
    },
    uploadRecording() {
      const that = this;
      wx.uploadVoice({
        localId: that.localId,
        // 需要上传的音频的本地ID，由stopRecord接口获得
        isShowProgressTips: 1,
        // 默认为1，显示进度提示
        success: function (res) {
          that.serverId = res.serverId; // 返回音频的服务器端ID
        }
      });
    },

    getLocation(index) {
      const that = this;
      wx.getLocation({
        type: 'wgs84',
        // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function (res) {
          that.latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          that.longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          that.getAddress(index);
        }
      });
    },
    async getAddress(index) {
      const ajaxData = {};
      ajaxData.location = this.latitude + ',' + this.longitude;
      const resultData = await getAddress(ajaxData);
      if (resultData.code === 200) {
        this.questionnaireItemList[index].content = resultData.data.result.address + '(' + resultData.data.result.formatted_addresses.recommend + ')';
      }
    }
  }
};