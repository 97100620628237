import request from '@/utils/request'

export function uploadFile(formData) {
  return request({
    url: '/userApi/file/uploadFile',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
